/* MultilineSelect.module.css */

  .multiselect-wrapper {
    position: relative;
    width: fit-content;
  }
  
  .selected-option {
    border-radius: 10px;
    background-color: #29353E;
    padding: 5px;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .dropdown {
    /* position: absolute;
    top: 100%;
    left: 0; */
    border-radius: 10px;
    background-color: #29353E;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
  }
  
  .option {
    padding: 5px;
    cursor: pointer;
  }
  
  .option:hover {
    background-color: #51616D;
  }
  
  .pointer {
    cursor: pointer;
  }