.styled-radio-button {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}
  
.styled-radio-button.with-label {
    margin-right: 10px; /* Apply margin only when the label is present */
}

.styled-radio-button input[type="radio"] {
    display: none; /* Hide the default radio input */
}

.radio-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    margin-right: 5px;
    display: inline-block;
    background-color: white;
}

.styled-radio-button input[type="radio"]:checked + .radio-indicator {
    background-color: #FFA726; /* Change the color when checked */
    border-color: #FFA726;
}

.tooltip {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.6s ease-in-out;
}
  
.styled-radio-button:hover .tooltip {
    opacity: 1;
}
